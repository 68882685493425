import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const ItemCard = (props) => {
  return (
    <SCard className="itemcard">
      <Link to={props.url}>
        <div>
          <img src={props.img} alt="itemcard" />
        </div>
        <div>
          <span>{props.brand}</span>
          <span>{props.name}</span>
          <span>¥ {props.price}</span>
        </div>
      </Link>
    </SCard>
  );
};

const SCard = styled.div`
  width: 100%;
  background-color: ${Color.white};
  a {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    div {
      &:first-of-type {
        overflow: hidden;
        width: 100%;
        aspect-ratio: 1/1;
        img {
          transition: all 0.2s;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &:last-of-type {
        padding: 1rem 0rem;
        display: inline-flex;
        flex-direction: column;
        span {
          color: ${Color.gray400};
          line-height: 1;
          &:nth-of-type(1) {
            font-size: 0.8rem;
          }
          &:nth-of-type(2) {
            margin-top: 0.5rem;
            font-size: 1rem;
            font-weight: 500;
          }
          &:nth-of-type(3) {
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        div {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        div {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
`;
