import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const ViewMoreBtn = (props) => {
  return (
    <SBtn>
      <Link to={props.url}>
        <span>もっと見る</span>
        <i className="material-icons-outlined">navigate_next</i>
      </Link>
    </SBtn>
  );
};

const SBtn = styled.span`
  a {
    display: inline-flex;
    align-items: center;
    span {
      display: inline-flex;
      align-items: center;
      height: 24px;
      color: ${Color.gray400};
      font-size: 0.8rem;
      transition: all 0.2s;
    }
    i {
      height: 24px;
      transition: all 0.2s;
      color: ${Color.gray400};
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        span {
          color: ${Color.blue};
        }
        i {
          color: ${Color.blue};
          transform: translateX(0.5rem);
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        span {
          color: ${Color.blue};
        }
        i {
          color: ${Color.blue};
          transform: translateX(0.5rem);
        }
      }
    }
  }
`;
