import Genre01 from "../images/genre_01.png";
import Genre02 from "../images/genre_02.png";
import Genre03 from "../images/genre_03.png";
import Genre04 from "../images/genre_04.png";
import Genre05 from "../images/genre_05.png";
import Genre06 from "../images/genre_06.png";

export const GenreLists = [
  { txt: "genre01", url: "/genre/genre01", img: Genre01 },
  { txt: "genre02", url: "/genre/genre01", img: Genre02 },
  { txt: "genre03", url: "/genre/genre01", img: Genre03 },
  { txt: "genre04", url: "/genre/genre01", img: Genre04 },
  { txt: "genre05", url: "/genre/genre01", img: Genre05 },
  { txt: "genre06", url: "/genre/genre01", img: Genre06 },
];
