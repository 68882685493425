import Item01 from "../images/item_01.jpg";
import Item02 from "../images/item_02.jpg";
import Item03 from "../images/item_03.jpg";
import Item04 from "../images/item_04.jpg";
import Item05 from "../images/item_05.jpg";
import Item06 from "../images/item_06.jpg";
import Item07 from "../images/item_07.jpg";
import Item08 from "../images/item_08.jpg";
import Item09 from "../images/item_09.jpg";
import Item10 from "../images/item_10.jpg";
import Item11 from "../images/item_11.jpg";
import Item12 from "../images/item_12.jpg";
import Item13 from "../images/item_13.jpg";
import Item14 from "../images/item_14.jpg";
import Item15 from "../images/item_15.jpg";
import Item16 from "../images/item_16.jpg";
import Item17 from "../images/item_17.jpg";
import Item18 from "../images/item_18.jpg";
import Item19 from "../images/item_19.jpg";
import Item20 from "../images/item_20.jpg";

export const ItemLists = [
  {
    id: 1,
    name: "Item01",
    price: "11,000",
    genre: "genre01",
    brand: "brand01",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item01,
  },
  {
    id: 2,
    name: "Item02",
    price: "21,000",
    genre: "genre02",
    brand: "brand01",
    sale: true,
    pickup: false,
    url: "/items/item01",
    img: Item02,
  },
  {
    id: 3,
    name: "Item03",
    price: "10,000",
    genre: "genre03",
    brand: "brand02",
    sale: false,
    pickup: true,
    url: "/items/item01",
    img: Item03,
  },
  {
    id: 4,
    name: "Item04",
    price: "10,000",
    genre: "genre04",
    brand: "brand04",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item04,
  },
  {
    id: 5,
    name: "Item05",
    price: "14,000",
    genre: "genre04",
    brand: "brand05",
    sale: false,
    pickup: false,
    url: "/items/item01",
    img: Item05,
  },
  {
    id: 6,
    name: "Item06",
    price: "13,000",
    genre: "genre06",
    brand: "brand01",
    sale: false,
    pickup: true,
    url: "/items/item01",
    img: Item06,
  },
  {
    id: 7,
    name: "Item07",
    price: "18,000",
    genre: "genre01",
    brand: "brand06",
    sale: true,
    pickup: false,
    url: "/items/item01",
    img: Item07,
  },
  {
    id: 8,
    name: "Item08",
    price: "21,000",
    genre: "genre06",
    brand: "brand06",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item08,
  },
  {
    id: 9,
    name: "Item09",
    price: "31,000",
    genre: "genre03",
    brand: "brand04",
    sale: false,
    pickup: false,
    url: "/items/item01",
    img: Item09,
  },
  {
    id: 10,
    name: "Item10",
    price: "20,000",
    genre: "genre02",
    brand: "brand04",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item10,
  },
  {
    id: 11,
    name: "Item11",
    price: "11,000",
    genre: "genre01",
    brand: "brand01",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item11,
  },
  {
    id: 12,
    name: "Item12",
    price: "21,000",
    genre: "genre02",
    brand: "brand01",
    sale: true,
    pickup: false,
    url: "/items/item01",
    img: Item12,
  },
  {
    id: 13,
    name: "Item13",
    price: "10,000",
    genre: "genre03",
    brand: "brand02",
    sale: false,
    pickup: true,
    url: "/items/item01",
    img: Item13,
  },
  {
    id: 14,
    name: "Item14",
    price: "10,000",
    genre: "genre04",
    brand: "brand04",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item14,
  },
  {
    id: 15,
    name: "Item15",
    price: "14,000",
    genre: "genre04",
    brand: "brand05",
    sale: false,
    pickup: false,
    url: "/items/item01",
    img: Item15,
  },
  {
    id: 16,
    name: "Item16",
    price: "13,000",
    genre: "genre06",
    brand: "brand01",
    sale: false,
    pickup: true,
    url: "/items/item01",
    img: Item16,
  },
  {
    id: 17,
    name: "Item17",
    price: "18,000",
    genre: "genre01",
    brand: "brand06",
    sale: true,
    pickup: false,
    url: "/items/item01",
    img: Item17,
  },
  {
    id: 18,
    name: "Item18",
    price: "21,000",
    genre: "genre06",
    brand: "brand06",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item18,
  },
  {
    id: 19,
    name: "Item19",
    price: "31,000",
    genre: "genre03",
    brand: "brand04",
    sale: false,
    pickup: false,
    url: "/items/item01",
    img: Item19,
  },
  {
    id: 20,
    name: "Item20",
    price: "20,000",
    genre: "genre02",
    brand: "brand04",
    sale: true,
    pickup: true,
    url: "/items/item01",
    img: Item20,
  },
];
