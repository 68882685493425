import React from "react";
import styled from "@emotion/styled";
// components
import { SectionHeading } from "../heading/sectionHeading";
import { GenreCard } from "../card/genreCard";
// utils
import { Color } from "../../utils/color";
import { GenreLists } from "../../utils/genreLists";

export const GenreSection = () => {
  return (
    <SSection>
      <SContainer>
        <SectionHeading txt="ジャンルから探す" />
        <SWrapper>
          {GenreLists.map(({ txt, url, img }) => {
            return <GenreCard key={txt} txt={txt} url={url} img={img} />;
          })}
        </SWrapper>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  gap: 1rem;
  a {
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: ${Color.gray100};
    img {
      width: 80px;
      height: 80px;
    }
    span {
      color: ${Color.gray400};
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1;
      margin-top: 2rem;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
  }
`;
