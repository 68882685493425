import React from "react";
import styled from "@emotion/styled";
// components
import { SectionHeading } from "../heading/sectionHeading";
import { ViewMoreBtn } from "../button/viewmoreBtn";
import { ItemCard } from "../card/itemCard";
// utils
import { Color } from "../../utils/color";
import { ItemLists } from "../../utils/itemLists";

// sale === trueを抽出
const SaleItemLists = ItemLists.filter((Item) => {
  return Item.sale === true;
});

export const SaleSection = () => {
  return (
    <SSection>
      <SContainer>
        <SectionHeading txt="セール中の製品" />
        <SWrapper>
          {SaleItemLists.map(({ id, name, price, brand, url, img }) => {
            return (
              <ItemCard
                key={id}
                name={name}
                price={price}
                brand={brand}
                url={url}
                img={img}
              />
            );
          })}
        </SWrapper>
        <SFooting>
          <ViewMoreBtn url="/sale" />
        </SFooting>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  gap: 1rem;
  /* 上から５件まで表示 */
  .itemcard {
    &:nth-of-type(n + 6) {
      display: none;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
    gap: 0.5rem;
  }
`;

const SFooting = styled.div`
  margin-top: 2rem;
`;
